export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Configuration
  configurationRoot: '/configuration',
  configurationList: '/configuration/list',
  configurationCreateRoot: '/configuration/create',
  configurationCreateDetails: '/configuration/create/details',
  configurationCreateIndexes: '/configuration/create/:configurationId/indexes',
  configurationCreateIndexesDetails: '/configuration/create/:configurationId/indexes/:indexId/details',
  configurationCreateSearchFieldDetails: '/configuration/create/:configurationId/search-fields/:searchFieldId/details',
  configurationCreateSearchField: '/configuration/create/:configurationId/search-fields',

  configurationViewRoot: '/configuration/view/:configurationId',
  configurationViewDetails: '/configuration/view/:configurationId/details',

  configurationUpdateRoot: '/configuration/update/:configurationId',
  configurationUpdateDetails: '/configuration/update/:configurationId/details',

  configurationUpdateIndexesRoot: '/configuration/update/:configurationId/indexes',
  configurationUpdateIndexesDefinition: '/configuration/update/:configurationId/indexes/definition',
  configurationUpdateIndexesDetails: '/configuration/update/:configurationId/indexes/:indexId/details',

  configurationUpdateSearchFieldRoot: '/configuration/update/:configurationId/search-fields',
  configurationUpdateSearchFieldDefinition: '/configuration/update/:configurationId/search-fields/definition',
  configurationUpdateSearchFieldDetails: '/configuration/update/:configurationId/search-fields/:searchFieldId/details',

  // Data management
  dataManagementRoot: '/data-management',
  dataManagementDataUploadList: '/data-management/data-upload/list',
  dataManagementDataUploadViewRoot: '/data-management/data-upload/view/:dataUploadId',

  dataManagementDataSearchList: '/data-management/data-search/list',
  dataManagementDataSearchVRecordDetails: '/data-management/data-search/:indexId/record/:recordId/details',
  dataManagementDataSearchInnerRecordDetails:
    '/data-management/data-search/:indexId/record/:recordId/inner/:innerRecordId/details',

  dataManagementUserAuditList: '/data-management/user-audit/list',

  // Reporting
  reportingRoot: '/reporting',

  // Product Information
  productInfo: '/product-info',
};

export { GuardedRoute } from './GuardedRoute';
