import { useMemo } from 'react';

import { useSelector, useDispatch } from 'src/store';
import { selectedCompanyIdSelector, allCompanyOptionsSelector } from 'src/store/selectors/companySelector';
import { selectCompany } from 'src/store/slices/companySlice';

import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';
import { dropdownFilterOption } from '@itm/shared-frontend/lib/utils';

import { SelectOption } from 'src/types';

function CompanyFilter() {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);

  const isFilterAvailable = true;

  const selectedCompanyOption = useMemo(
    () => companyOptions.find((option) => option.value === selectedCompanyId) || null,
    [companyOptions, selectedCompanyId],
  );

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };

  return (
    <div className="is-flex is-justify-content-center pt-5">
      <div className="control is-fullwidth">
        <Select
          className="react-select"
          classNamePrefix="react-select"
          value={selectedCompanyOption}
          options={companyOptions}
          placeholder={companyOptions.length ? 'Company Name' : 'No companies'}
          onChange={companyChangeHandler}
          isDisabled={!isFilterAvailable || !companyOptions.length}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          filterOption={dropdownFilterOption}
        />
      </div>
    </div>
  );
}

export default CompanyFilter;
