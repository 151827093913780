import { Breadcrumb } from '@itm/shared-frontend/lib/components/Breadcrumbs';
import { GlobalRole } from '@itm/shared-frontend/lib/types';

export { ResultFailType } from '@itm/shared-frontend';
export type {
  GlobalRole,
  SelectOption,
  ServerError,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'eArchiveLite_Analyst',
  ClientAdmin = 'eArchiveLite_ClientAdmin',
  Manager = 'eArchiveLite_Manager',
}

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export type BreadcrumbProps = {
  breadcrumbsBase: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
  isBreadcrumbsReady: boolean;
};

export type SchemeResponse = {
  id: string;
  name: string;
  companyId: string;
};

export type ConfigurationSearchResponse = {
  id: string;
  name: string;
  status: string;
  createdDate: string;
  updatedDate: string;
};

export type ConfigurationSearchResponseWithCount = {
  totalCount: number;
  items: ConfigurationSearchResponse[];
};

export type UserAuditResponse = {
  id: string;
  params: string | null;
  userId: string;
  userName: string | null;
  elapsed: string;
  type: UserAuditType;
  status: UserAuditStatus;
  dateTime: string;
};

export type UserAuditRecordsResponseWithCount = {
  totalCount: number;
  items: UserAuditResponse[];
};

export type DataUploadReponse = {
  id: string;
  fileName: string;
  indexName: string;
  status: DataUploadStatus;
  error: string;
  createdDate: string;
  updatedDate: string;
  schemes: SchemeResponse[];
};

export type DataUploadSearchResponseWithCount = {
  totalCount: number;
  items: DataUploadReponse[];
};

export type CreateConfigurationModel = {
  name: string;
  companyId: string;
  elasticSearchServerId: string;
  schemes: string[];
};

export type ConfigurationSchemeResponse = {
  id: string;
  name: string | null;
};

export type CompanyResponse = {
  id: string;
  name: string;
};

export type ConfigurationDetailsResponse = {
  id: string;
  name: string;
  status: string;
};

export type ElasticSearchServerReponse = {
  id: string;
  name: string;
};

export type CreateIndexModel = {
  configurationId: string;
  name: string;
  sourceFileName: string;
};

export type ConfigurationIndexResponse = {
  id: string;
  name: string;
  sourceFileName: string;
  status: IndexStatus;
  createdDate: string;
  updatedDate: string | null;
};

export type SearchFieldResponse = {
  id: string;
  name: string;
  displayName: string;
  type: string;
  useInFilter: boolean;
  indexId: string;
  indexName: string;
  createdDate: string;
  updatedDate: string | null;
};

export type ConfigurationResponse = {
  id: string;
  name: string;
  elasticServer: ElasticSearchServerReponse;
  schemes: SchemeResponse[];
};

export type UpdateConfigurationModel = {
  id: string;
  name: string;
  companyId: string;
  schemes: string[];
};

export type UpdateIndexModel = {
  id: string;
  name: string;
  sourceFileName: string;
  configurationId: string;
};

export type UpdateSearchFieldModel = {
  companyId: string;
  id: string;
  name: string;
  displayName: string;
  configurationId: string;
  indexId: string;
  type: string;
};

export type IndexDataResponse = {
  id: string;
  indexId: string;
  values: Record<string, string>;
  sections: IndexDataResponseSection[];
  multiSections: IndexDataResponseMultipleSection[];
};

export type DataSearchResponseWithCount = {
  totalCount: number;
  items: IndexDataResponse[];
};

export type IndexDataSearchRecordModel = {
  companyId: string;
  recordId: string;
  indexId: string;
  innerRecordId: string;
};

export type IndexDataResponseSection = {
  name: string;
  parentName: string;
  hasExtraDetails: boolean;
  values: Record<string, string>;
};

export type IndexDataResponseMultipleSection = {
  name: string;
  parentName: string;
  values: Record<string, string>[];
};

export type IndexDataSearchFilterResponse = {
  name: string;
  type: FilterType;
};

export type IndexDataSearchFilterModel = {
  name: string;
  value: string;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export enum FilterType {
  Text = 'Text',
  Date = 'Date',
}

export enum ConfigurationStatus {
  IndexCreationPending = 'IndexCreationPending',
  SearchDefinitionPending = 'SearchDefinitionPending',
  IndexCreated = 'IndexCreated',
  SearchDefinitionCreated = 'SearchDefinitionCreated',
}

export enum DataUploadStatus {
  PendingUpload = 'PendingUpload',
  Uploaded = 'Uploaded',
  UploadError = 'UploadError',
  Deleting = 'Deleting',
  Deleted = 'Deleted',
  DeletedError = 'DeletedError',
}

export enum IndexStatus {
  Creating = 'Creating',
  Created = 'Created',
  Error = 'Error',
  DataLoaded = 'DataLoaded',
}

export enum UserAuditType {
  NotSupported = 'NotSupported',
  Search = 'Search',
  Download = 'Download',
}

export enum UserAuditStatus {
  NotFound = 'NotFound',
  Ok = 'Ok',
  BadRequest = 'BadRequest',
}
